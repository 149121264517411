
import { mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Search',
  components: {
    VSub: () => import('@/components/Header/VMenu-sub'),
    VSubBrand: () => import('@/components/Header/VMenu-subBrand'),
    VMobile: () => import('@/components/Header/VMenu-mobile')
  },
  props: {
    pageMenu: {
      type: Array,
      default() {}
    },
    brandsMenu: {
      type: Array,
      default() {}
    },
    bgColors: {
      type: String,
      default: '#000000'
    },
    txtColors: {
      type: String,
      default: '#FFFFFF'
    }
  },
  data: () => ({
    search: '',
    isMobile: false,
    subMM: false
  }),
  beforeMount() {
    window.addEventListener('resize', this.handleMobile)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleMobile)
  },
  mounted() {
    this.handleMobile()
  },
  computed: {
    ...mapGetters({
      mobileMenuOpen: 'layout/GGmobileMenuOpen'
    }),
    cssProps() {
      return {
        '--bg-color': this.bgColors,
        '--txt-color': this.txtColors
      }
    },
    orderedMenu() {
      return this.orderMenu(this.pageMenu);
    }
  },
  methods: {
    ...mapMutations({
      showOverlay: 'overlay/showOverlay',
      hideOverlay: 'overlay/hideOverlay',
      customZIndex: 'overlay/customZIndex',
      customTop: 'overlay/customTop'
    }),
    ...mapActions({
      toggleMobileMenu: 'layout/AAmobileMenuOpen'
    }),
    handleMobile() {
      if (window.innerWidth <= 992) {
        this.isMobile = true
      } else {
        this.isMobile = false
        this.hideOverlay()
        this.customTop(false)
        this.toggleMobileMenu(false)
        this.customZIndex(998)
      }
    },
    OverlayShow() {
      this.customTop(false)
      this.showOverlay()
      this.subMM = true
    },
    OverlayHide() {
      this.hideOverlay()
      this.subMM = false
    },
    orderMenu(order) {
      return order.sort((a, b) => a.order - b.order)
    },
    formartMenuHighlighted(title) {
      const menuText = title.split(' ')
      const menuBegain = menuText.slice(0, menuText.length - 1)
      const menuEnds = menuText.pop()
      let menuFormats = ''
      for (const menu of menuBegain) {
        menuFormats = menuFormats + ' ' + menu
      }
      return `${menuFormats.replace(' ', '')} <span>${menuEnds}</span>`
    }
  }
}
